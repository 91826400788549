@import "../../assets/style/variable.scss";
.testimonial-slide {
    background: linear-gradient(0deg, #FDFDFD, #FDFDFD), #1B0C4C;
    box-shadow: 0px 4.63875px 97.4138px rgba(0, 0, 0, 0.14);
    border-radius: 31.8914px;
    padding: 41px 52px 53px 41px;
    position: relative;
    &::after {
        content: '';
        background-image: url(../../assets/images/testimonial_bottom.png);
        background-position: center;
        background-repeat: no-repeat;
        background-size: contain;
        width: 64px;
        height: 26px;
        position: absolute;
        bottom: -26px;
        left: calc(50% - 32px);
    }
    .title {
        font-weight: 700;
    }
    .rating {
        .star {
            height: 26px;
            width: 26px;
            stroke-width: 1px;
            stroke-linecap: square;
            stroke: $green-second;
            margin-right: 5px;
            cursor: pointer;
            &:last-child {
                margin-right: 0;
            }
            &:hover {
                fill: $green-second;
            }
            &.active {
                fill: $green-second;
            }
        }
    }
    .testimonial-profile {
        display: flex;
        align-items: center;
        margin-top: 32px;
        .image {
            .avtar {
                height: 56px;
                width: 60px;
                box-shadow: 0px 1.72965px 36.3228px rgba(0, 0, 0, 0.14);
                border-radius: 10px;
                margin-right: 18px;
            }
        }
        .content {
            .text {
                .name {
                    font-weight: 700;
                    margin-bottom: 0;
                    margin-top: 14px;
                }
                .position {
                    color: $dark;
                }
            }
        }
    }
}

@media only screen and (max-width: 599px) {
    .testimonial-slide {
        padding: 24px;
        .testimonial-profile {
            margin-top: 12px;
            .image {
                .avtar {
                    height: 50px;
                    width: 56px;
                    margin-right: 14px;
                }
            }
        }
    }
}
//
// Color
//
$white: #ffffff;
$dark: #0B0732;
$dark-1b0c4c: #1B0C4C;
$green: #51D7C1;
$green-second: #3FD9A1;
$green-third: #3CC7BC;
$d-green: #007A66;
$grey: #A09EB9;
$grey-d0: #d0d0d0;
$grey-ea: #eaeaea;
$grey-e1: #E1E1E1;
//
// Font Size
//
$fs-10: 10px;
$fs-12: 12px;
$fs-13: 13px;
$fs-14: 14px;
$fs-16: 16px;
$fs-18: 18px;
$fs-19: 19px;
$fs-20: 20px;
$fs-21: 21px;
$fs-22: 22px;
$fs-24: 24px;
$fs-25: 25px;
$fs-26: 26px;
$fs-28: 28px;
$fs-30: 30px;
$fs-32: 32px;
$fs-34: 34px;
$fs-35: 35px;
$fs-36: 36px;
$fs-38: 38px;
$fs-40: 40px;
$fs-42: 42px;
$fs-44: 44px;
$fs-45: 45px;
$fs-46: 46px;
$fs-48: 48px;
$fs-50: 50px;
$fs-62: 62px;
$fs-80: 80px;
$fs-1rem: 1rem;
$fs-1-25rem: 1.25rem;
$fs-1-5rem: 1.5rem;
$fs-1-75rem: 1.75rem;
$fs-2rem: 2rem;
$fs-2-5rem: 2.5rem;
//
// Font Weight
//
$fw-300: 300;
$fw-400: 400;
$fw-500: 500;
$fw-600: 600;
$fw-700: 700;
$fw-800: 800;
$fw-900: 900;
//
// Border Radius
//
$br-10: 10px;
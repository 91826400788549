@import "../../assets/style/variable.scss";
#footer {
    background: $white;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.08);
    padding: 33px 0;
    position: fixed;
    width: 100%;
    bottom: 0;
    z-index: 99;
    .footer-col-first {
        height: 100%;
        display: flex;
        align-items: center;
        .footer-logo {
            max-width: 196px;
        }
    }
    .footer-col-second {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        ul {
            display: inline-flex;
            li {
                margin-right: 20px;
                &:last-child {
                    margin-right: 0px;
                }
            }
        }
    }
    .footer-col-third {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: end;
    }
    .feather {
        color: $green;
        font-size: $fs-16;
    }
}

@media only screen and (max-width: 1199px) {
    #footer {
        display: none;
        .footer-col-first {
            display: block;
            text-align: center;
            margin-bottom: 1rem;
        }
        .footer-col-second {
            display: block;
            text-align: center;
            margin-bottom: 1rem;
        }
        .footer-col-third {
            justify-content: center;
        }
    }
}
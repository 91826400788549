@font-face {
    font-family: 'Roboto Thin';
    src: local('Roboto Thin'), url('../fonts/roboto/roboto-thin.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Thin Italic ';
    src: local('Roboto Thin Italic'), url('../fonts/roboto/roboto-thin-italic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Light';
    src: local('Roboto Light'), url('../fonts/roboto/roboto-light.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Light Italic';
    src: local('Roboto Light Italic'), url('../fonts/roboto/roboto-light-italic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Regular';
    src: local('Roboto Regular'), url('../fonts/roboto/roboto-regular.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Medium';
    src: local('Roboto Medium'), url('../fonts/roboto/roboto-medium.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Italic';
    src: local('Roboto Italic'), url('../fonts/roboto/roboto-italic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Medium Italic';
    src: local('Roboto Medium Italic'), url('../fonts/roboto/roboto-medium-italic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Bold';
    src: local('Roboto Bold'), url('../fonts/roboto/roboto-bold.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Bold Italic';
    src: local('Roboto Bold Italic'), url('../fonts/roboto/roboto-bold-italic.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Black';
    src: local('Roboto Black'), url('../fonts/roboto/roboto-black.woff') format('woff');
}

@font-face {
    font-family: 'Roboto Black Italic';
    src: local('Roboto Black Italic'), url('../fonts/roboto/roboto-black-italic.woff') format('woff');
}

body {
    background-color: $white;
    font-family: 'Roboto Regular';
}

p {
    color: $grey;
    font-size: 16px;
    line-height: 26px;
}

// List
ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

// Anchor tag
a {
    color: inherit;
    text-decoration: none;
    &:hover {
        color: inherit;
    }
}

.btn-link {
    color: #0B0732;
    &:hover {
        color: #363442;
    }
}

hr {
    margin: 1.5rem 0;
    background-color: #EBEBEB;
    opacity: 1;
}

// Heading
// h1 {
//     font-family: 'Roboto Medium';
// }
// h2 {
//     font-family: 'Roboto Medium';
// }
// h3 {
//     font-family: 'Roboto Medium';
// }
// h4 {
//     font-family: 'Roboto Medium';
// }
// h5 {
//     font-family: 'Roboto Medium';
// }
// h6 {
//     font-family: 'Roboto Medium';
// }
.display-1 {
    font-family: 'Roboto Light';
    font-size: $fs-80;
}

.display-2 {
    font-family: 'Roboto Light';
}

.display-3 {
    font-family: 'Roboto Light';
}

.display-4 {
    font-family: 'Roboto Light';
}

.display-5 {
    font-family: 'Roboto Light';
    font-size: $fs-48;
}

.display-6 {
    font-family: 'Roboto Light';
}

// Background Color Start
.bg-theme-gradient {
    background: linear-gradient(180deg, rgba(81, 215, 193, 0.9) 0%, rgba(27, 12, 76, 0.9) 100%);
}

.bg-white-gradient {
    background: linear-gradient(180deg, #FFFFFF 70.31%, #F9F9F9 100%);
}

.bg-light-blue {
    background-color: #EBF3FB;
}

.bg-green-100 {
    background-color: #D7FAED;
}

// Background Color End
// Font Size Start
.fs-14 {
    font-size: $fs-14 !important;
}

.fs-16 {
    font-size: $fs-16 !important;
}

.fs-18 {
    font-size: $fs-18 !important;
}

.fs-20 {
    font-size: $fs-20 !important;
}

.fs-22 {
    font-size: $fs-22 !important;
}

.fs-24 {
    font-size: $fs-24 !important;
}

// Font Size End
// Font Weight Start
.fw-300 {
    font-weight: 300;
}

.fw-400 {
    font-weight: 400;
}

.fw-500 {
    font-weight: 500;
    font-family: 'Roboto Bold';
}

.fw-600 {
    font-weight: 600;
}

.fw-700 {
    font-weight: 700;
}

.fw-800 {
    font-weight: 800;
}

.fw-900 {
    font-weight: 900;
}

// Font Weight End
// Font Color Start
.f-green {
    color: $green;
}

.f-green-second {
    color: $green-second;
}

.f-green-2 {
    color: $green-third;
}

.f-grey-c2c2c2 {
    color: #C2C2C2;
}

.f-black {
    color: #0B0732;
}

.f-blue {
    color: #353367;
}

// Font Color End
// Height Start
.min-height-92 {
    min-height: 92px;
}

// Height End
// Border Radius Start
.br-10 {
    border-radius: 10px;
}

.br-30 {
    border-radius: 30px;
}

.br-1100 {
    border-radius: 10px 10px 0px 0px;
}

// Border Radius End
.box-shadow-1 {
    box-shadow: 0px 0px 250px rgba(0, 0, 0, 0.05);
}

.shadow-2 {
    box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.1);
}

// Button Start
.btn {
    border-radius: 10px;
    border-width: 2px;
}

.btn-lg {
    border-radius: 20px;
    font-size: $fs-24;
    padding: 20px 28px;
}

// Button White
.btn-white {
    color: $dark;
    background-color: $white;
    border-color: $white;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    &:hover {
        color: $white;
        background-color: $dark;
        border-color: $dark;
    }
    &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }
    &.active {
        color: $white;
        background-color: $dark;
        border-color: $dark;
    }
}

.btn-light-blue {
    color: $dark;
    background-color: #EBF3FB;
    border-color: #EBF3FB;
    &:hover {
        color: #EBF3FB;
        background-color: $dark;
    }
}

// Button Green
.btn-green {
    color: $white;
    background-color: $green;
    border-color: $green;
    box-shadow: 0px 0px 24px rgba(0, 0, 0, 0.12);
    &:hover {
        color: $green;
        background-color: transparent;
        border-color: $green;
    }
    &:focus {
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.25);
    }
    &.active {
        color: $green;
        background-color: transparent;
        border-color: $green;
    }
}

// Button Outline Green
.btn-outline-green {
    color: $dark;
    background-color: $white;
    border-color: $green;
    &:hover {
        color: $white;
        background-color: $green;
        border-color: $green;
    }
    &:focus {
        box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.2);
    }
}

// --------
// Button End
// --------
// --------
// Form
// --------
.form-label {
    font-size: $fs-20;
}

.form-control {
    padding: 24px 32px;
    font-size: $fs-20;
    border: none;
    box-shadow: 0px 0px 84px rgba(0, 0, 0, 0.12);
    border-radius: 20px;
    color: #A09EB9;
    &:focus {
        box-shadow: 0 0 10px .25rem rgba(166, 166, 166, 0.25);
    }
    &::placeholder {
        color: #A09EB9;
    }
    &.control-type-2 {
        border: 2px solid #EEEEEE;
        box-shadow: none;
        &:focus {
            box-shadow: none;
            border-color: #c9c9c9;
        }
    }
}

.form-select {
    background-image: url("../images/dropdown_arrow.svg");
    background-position: right 30px center;
    background-size: 17px 8.5px;
    padding-right: 50px !important;
}

// Modal Start
.modal-backdrop {
    background: linear-gradient(180deg, #51d7c1 0%, #1b0c4c 100%);
    &.fade {
        opacity: 0;
    }
    &.show {
        opacity: 0.9;
    }
}

.modal-content {
    border: none;
    border-radius: 32px;
    background: #FDFDFD;
    box-shadow: 0px 4.63875px 97.4138px rgba(0, 0, 0, 0.14);
    .modal-body {
        padding: 60px;
    }
}

// Progressbar Start
.progress {
    height: 5px;
    background-color: $grey-ea;
    border-radius: 10px;
    .progress-bar {
        color: #fff;
        background-color: $green-second;
    }
}

// Avatar Start
.avatar {
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    font-size: 1rem;
    font-weight: 600;
    height: 2.875rem;
    width: 2.875rem;
    border-radius: 0.375rem;
}

.avatar-xs {
    width: 1.75rem;
    height: 1.75rem;
    font-size: 0.675rem;
    border-radius: 0.25rem;
}

.avatar-sm {
    width: 2.25rem;
    height: 2.25rem;
    font-size: 0.75rem;
    border-radius: 0.25rem;
}

.avatar-lg {
    width: 3.5rem;
    height: 3.5rem;
    font-size: 1.25rem;
}

.avatar-xl {
    width: 6rem;
    height: 6rem;
    font-size: calc(1.2625rem + 0.15vw);
}

// Avatar end
// Transform Rotate Start
.tr-90 {
    transform: rotate(90deg);
}

.tr-180 {
    transform: rotate(180deg);
}

.tr-270 {
    transform: rotate(270deg);
}

.tr-360 {
    transform: rotate(360deg);
}

.trn-90 {
    transform: rotate(-90deg);
}

.trn-180 {
    transform: rotate(-180deg);
}

.trn-270 {
    transform: rotate(-270deg);
}

.trn-360 {
    transform: rotate(-360deg);
}

// Transform Rotate End
// Table CSS Start
.table {
    color: #A09EB9;
    font-size: 14px;
    >* {
        border-top: none !important;
    }
    thead {
        tr {
            th {
                border: none;
                padding-bottom: 24px;
                font-weight: normal;
            }
        }
    }
    tbody {
        tr {
            td {
                border: none;
                font-weight: normal;
            }
        }
    }
}

// Table CSS End
@media only screen and (min-width: 599px) {}

@media only screen and (max-width: 600px) {}

@media only screen and (max-width: 767px) {}

@media only screen and (max-width: 991px) {
    .display-1 {
        font-size: $fs-45;
    }
    .display-5 {
        font-size: $fs-35;
    }
    .btn-lg {
        border-radius: 14px;
        font-size: $fs-18;
        padding: 16px 30px;
    }
    .form-label {
        font-size: $fs-16;
    }
    .form-control {
        padding: 18px 24px;
        font-size: $fs-14;
        border-radius: 14px;
    }
    .modal-content {
        .modal-body {
            padding: 44px 24px;
        }
    }
}

@media only screen and (max-width: 1199px) {
    .container {
        width: calc(100% - 30px);
    }
}

@media only screen and (min-width: 1200px) {
    .container {
        max-width: 1130px;
    }
}
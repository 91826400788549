.price-card {
    height: 100%;
    display: flex;
    flex-direction: column;
    .list-group {
        list-style: disc;
        .list-group-item {
            border: none;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                width: 6px;
                height: 6px;
                border-radius: 50%;
                background: #51D7C1;
                top: 50%;
                transform: translate(0, -50%);
                left: 0px;
            }
        }
    }
    .plan-price {
        font-family: 'Roboto Bold';
        margin-bottom: 23px;
        sub {
            font-family: 'Roboto Light';
            font-size: 18px;
            bottom: 0;
        }
    }
}
.sidebar {
    padding: 40px 0;
    background: #FFFFFF;
    box-shadow: 0px 4px 5.33333px rgba(0, 0, 0, 0.12), 0px 2.66667px 5.33333px rgba(0, 0, 0, 0.14);
    width: 90px;
    .navbar-brand {
        margin: 0 0 20px 0;
        text-align: center;
    }
    .nav-item {
        .nav-link {
            text-align: center;
            color: #B1B1B1;
            margin: 20px 0;
            font-size: 20px;
            &:hover {
                color: #0B0732;
            }
            &.active {
                color: #0B0732;
            }
        }
        &:last-child {
            .nav-link {
                margin-bottom: 0;
            }
        }
    }
}
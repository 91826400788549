@import "../../assets/style/variable.scss";
#header-white {
    padding: 32px 0;
    box-shadow: 0px 4px 64px rgba(0, 0, 0, 0.08);
    .navbar-toggler {
        border: none;
        &:focus {
            box-shadow: none;
        }
        .navbar-toggler-icon {
            background-image: url(../../assets/images/toggle.svg);
        }
    }
    .navbar-nav {
        .nav-link {
            color: $d-green !important;
            padding-left: 0;
            padding-right: 0;
            &:hover {
                opacity: 0.8;
            }
        }
        >* {
            margin-left: 15px;
            margin-right: 15px;
            &:last-child {
                margin-right: 0;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    #header-white {
        .navbar-nav {
            border-top: 0.25px solid #eee;
            padding: 10px 0px 0px 0px;
            margin-top: 20px;
            >* {
                margin-left: 0px;
                margin-right: 0px;
            }
        }
    }
}
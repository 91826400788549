.credit-card {
    max-width: 434px;
    width: 100%;
    height: 253px;
    padding: 28px 38px 0px 38px;
    background: #FFFFFF;
    box-shadow: 0px 0px 46.7848px rgba(0, 0, 0, 0.13);
    border-radius: 10px;
    background-repeat: no-repeat;
    background-position: right top;
    display: flex;
    flex-direction: column;
    align-items: start;
    border: 1px solid transparent;
    margin: 46px 0;
    &.disable {
        box-shadow: none;
        border-color: #DCDCDC;
        opacity: 0.7;
    }
    .card-input-main {
        margin-top: auto;
        .card-input {
            margin-bottom: 30px;
            .card-input-label {
                font-size: 14px;
                color: #B9B5B6;
                margin-bottom: 0;
            }
            .card-input-data {
                font-size: 20px;
                color: #555555;
                margin-bottom: 0;
            }
            .form-check-label {
                font-size: 20px;
                color: #555555;
                margin-bottom: 0;
            }
            .form-check-input {
                width: 36px;
                height: 24px;
                margin-right: 10px;
                background-size: 14px;
                background-position: 6px center;
                &:checked {
                    background-color: #56B2FF;
                    border-color: #56B2FF;
                    background-position: 16px center;
                }
            }
        }
    }
}

@media only screen and (max-width: 767px) {
    .credit-card {
        max-width: 250px;
        width: 100%;
        height: auto;
    }
}
@import "variable.scss";
@import "helper.scss";
.company-watermark {
    position: fixed;
    z-index: 100;
    right: 40px;
    bottom: 40px;
    color: $dark;
    opacity: 20%;
    &.o-60 {
        opacity: 60%;
    }
    p {
        margin-bottom: 0;
        a {
            font-family: 'Roboto Bold';
            text-decoration: underline !important;
            color: inherit;
        }
    }
}

#home {
    min-height: 100vh;
    background-image: url(../images/gradient_bg.svg);
    background-position: 50vw top;
    background-size: cover;
    background-repeat: no-repeat;
    padding-top: 122px;
    overflow: hidden;
    width: 100%;
    position: relative;
    .content {
        .top {
            padding-bottom: 138px;
            border-bottom: 2px dashed #E1E1E1;
            margin-bottom: 58px;
            .title {
                span {
                    font-family: 'Roboto Medium Italic';
                }
            }
        }
    }
    .human-image {
        position: relative;
    }
    .shape-1 {
        position: absolute;
        bottom: 0;
        left: 0;
        max-width: calc(100vw / 4);
        z-index: -1;
    }
    .shape-2 {
        position: absolute;
        bottom: 40%;
        right: 0;
        max-width: calc(100vw / 2);
    }
    .shape-3 {
        position: absolute;
        bottom: 0%;
        left: 50%;
        max-width: calc(100vw / 2);
    }
    .shape-4 {
        position: absolute;
        top: 0;
        right: 10%;
        max-width: calc(100vw / 2);
    }
}

#about {
    padding: 30px 0;
    min-height: calc(100vh - 124px);
    display: flex;
    align-items: center;
    background-image: url(../images/bg/about_bg.svg);
    background-position-x: center;
    background-position-y: bottom;
    background-size: cover;
    background-repeat: no-repeat;
    .left {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: end;
        .title {
            span {
                font-family: 'Roboto Medium Italic';
            }
        }
        .progress-circle {
            max-width: 230px;
            margin-left: auto;
            margin-right: auto;
        }
        button {
            width: fit-content;
        }
    }
}

#case-study {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 124px);
    padding: 30px 0;
    background-image: url(../images/gradient_bg.svg);
    background-position-x: calc(100% - 77vw);
    background-position-y: center;
    background-size: cover;
    background-repeat: no-repeat;
    .right {
        max-width: 452px;
        .main-title {
            margin-bottom: 31px;
        }
        .circle-title {
            color: $grey-d0;
            font-family: 'Roboto Bold';
            display: flex;
            align-items: center;
            .circle {
                height: 24px;
                width: 24px;
                border-radius: 50%;
                border: 6px solid $grey-d0;
                margin-right: 6px;
            }
        }
        .subtitle {
            margin-bottom: 8px;
            font-family: 'Roboto Bold';
        }
        .text {
            margin-bottom: 34px;
        }
    }
}

.case-study-col {
    filter: drop-shadow(0px 2.75157px 57.783px rgba(0, 0, 0, 0.14));
    margin-bottom: 30px;
    .case-study-person {
        width: 122px;
        height: 135px;
        padding: 1rem;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        mask-image: url(../images/mask_shape.svg);
        mask-repeat: no-repeat;
        mask-position: center;
        margin: auto;
    }
}

#testimonial {
    min-height: calc(100vh - 124px);
    padding: 16.33vh 0 12.77vh 0;
    overflow: hidden;
    .testimonial-carousel {
        margin-bottom: 80px;
        .slick-list {
            overflow: visible;
            .slick-slide {
                padding: 0px 8px;
            }
        }
    }
}

#test {
    background-image: url(../images/gradient_bg.svg);
    background-position-x: 73vw;
    background-position-y: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: calc(100vh - 250px);
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
    .content {
        max-width: 466px;
        margin: 30px 0;
    }
    .human {
        img {
            max-height: calc(100vh - 300px);
        }
    }
    .shape-1 {
        position: absolute;
        right: 50px;
        bottom: calc(0% - 180px);
        max-width: calc(100vw / 3);
    }
    .shape-2 {
        position: absolute;
        right: -125px;
        max-width: calc(100vw / 3);
    }
    .shape-3 {
        position: absolute;
        right: 0;
        max-width: calc(100vw / 3);
    }
    .shape-4 {
        position: absolute;
        right: 13vw;
        top: 10%;
        max-width: calc(100vw / 3);
    }
}

.progress-modal {
    .modal-content {
        margin-top: 75px;
        .human {
            position: absolute;
            bottom: 0;
            right: 20px;
        }
    }
}

.msg-email {
    .center {
        border-top: 2px dashed #EAEAEA;
        border-bottom: 2px dashed #EAEAEA;
        padding: 20px 0 20px 0;
        .msg-points {
            list-style: disc;
            padding-left: 1rem;
            li {
                color: #A09EB9;
                font-size: 20px;
                margin-bottom: 12px;
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
    .bottom {
        padding-top: 36px;
        .human {
            position: absolute;
            bottom: 0;
            right: 20px;
            max-width: 300px;
        }
    }
}

#create-account {
    min-height: calc(100vh - 124px);
    background-image: url(../images/gradient_bg.svg);
    background-position: 90vw center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    .title {
        span {
            font-family: "Roboto Medium Italic";
        }
    }
    .advantages {
        margin-bottom: 36px;
        border: 2px dashed #E1E1E1;
        border-radius: 10px;
        padding: 26px 36px 10px 36px;
        .advantage-line {
            color: #A09EB9;
            position: relative;
            margin-bottom: 0;
            margin-left: 16px;
            font-size: $fs-20;
            &::before {
                content: "";
                height: 4px;
                width: 4px;
                border-radius: 50%;
                background-color: #A09EB9;
                display: flex;
                position: absolute;
                top: calc(50% - 2px);
                left: -14px;
            }
        }
    }
    .container {
        position: relative;
        z-index: 1;
    }
    .shape-1 {
        position: absolute;
        left: 0;
        bottom: 0;
        max-width: calc(100vw / 3);
        z-index: 0;
    }
    .shape-2 {
        position: absolute;
        max-width: 33.3333333333vw;
        right: 0;
        top: 50%;
        transform: translatey(-50%);
        z-index: 0;
    }
}

// Nav Tab Start
.nav-tabs {
    border-bottom: 2px dashed $grey-e1;
    .nav-item {
        .nav-link {
            border: none;
            margin-bottom: 0;
            padding-left: 0;
            padding-right: 0;
            margin-right: 53px;
            font-size: $fs-24;
            color: #D9DBEC;
            &.active {
                color: $dark;
            }
        }
        &:last-child {
            .nav-link {
                margin-right: 0;
            }
        }
    }
}

.tab-content {
    padding: 3rem 0 0rem 0;
}

// Nav Tab End
// Log In Page Start
#log-in-forgot {
    background: linear-gradient(180deg, rgba(81, 215, 193, 0.9) 0%, rgba(27, 12, 76, 0.9) 100%);
    height: 100vh;
    overflow: hidden;
    width: 100%;
    padding: 5vh 0;
    position: relative;
    .logo {
        margin-bottom: 80px;
    }
    .title {
        span {
            font-family: "Roboto Medium Italic";
        }
    }
    .girl {
        position: absolute;
        bottom: 0;
        max-width: 50vw;
        transform: translateX(-100%);
        left: 50%;
        z-index: 1;
    }
    .shape-1 {
        position: absolute;
        right: 50px;
        bottom: calc(0% - 180px);
        max-width: calc(100vw / 3);
        z-index: 0;
    }
    .shape-2 {
        position: absolute;
        max-width: 33.3333333333vw;
        right: 10%;
        z-index: 0;
    }
    .shape-3 {
        position: absolute;
        right: 0;
        max-width: calc(100vw / 3);
        top: 0;
        transform: translatey(-50%);
        z-index: 0;
    }
    .shape-4 {
        position: absolute;
        left: 10vw;
        top: 10%;
        max-width: calc(100vw / 3);
        z-index: 0;
    }
    .shape-5 {
        position: absolute;
        max-width: calc(100vw / 3);
        left: 0;
        bottom: 0;
        transform: translatey(40%);
        z-index: 0;
    }
    .container {
        position: relative;
        z-index: 1;
    }
}

// .cone-wrapper {
//     position: absolute;
//     right: 0;
//     filter: drop-shadow(0px 4px 84px rgba(0, 0, 0, 0.1));
//     .cone {
//         height: 56px;
//         width: 78px;
//         background-color: #ffffff;
//         clip-path: polygon(0% 0%, 100% 50%, 0% 100%, 10% 50%);
//         display: block;
//     }
// }
// Dashboard Main
#dashboard-main {
    display: flex;
    flex-direction: row;
    height: 100vh;
    .content {
        flex: 1 1 0;
        overflow-y: auto;
        height: 100vh;
    }
}

.icon-input {
    position: relative;
    .icon-button {
        position: absolute;
        color: #ffffff;
        background: #3CC7BC;
        box-shadow: 0px 0px 84px rgba(0, 0, 0, 0.12);
        border-radius: 20px;
        height: calc(100% - 12px);
        right: 6px;
        top: 6px;
        bottom: 6px;
        font-size: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

// Table Start
.table-selector-star {
    position: relative;
    padding: 0;
    display: flex;
    align-items: center;
    .form-check-input {
        position: relative;
        z-index: 1;
        opacity: 0;
        min-height: auto;
        margin: 0;
        &:checked {
            + {
                .form-check-label {
                    background-color: #3FD9A1;
                    &::after {
                        background-color: #3FD9A1;
                    }
                }
            }
        }
    }
    .form-check-label {
        position: absolute;
        left: 0;
        top: 0;
        z-index: 0;
        clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
        height: 12px;
        width: 12px;
        background-color: #A09EB9;
        transform: translatey(50%);
        &::after {
            content: '';
            position: absolute;
            display: block;
            clip-path: polygon(50% 0%, 61% 35%, 98% 35%, 68% 57%, 79% 91%, 50% 70%, 21% 91%, 32% 57%, 2% 35%, 39% 35%);
            height: 10px;
            width: 10px;
            inset: 1px;
            background-color: #ffffff;
        }
    }
}

.eQqAsy {
    &:hover {
        background-color: #FCFCFC !important;
        color: #0B0732 !important;
    }
}

.dFQDzS {
    &:nth-of-type(n) {
        color: #0B0732 !important;
        background-color: #fcfcfc !important;
    }
}

// Table End
.add-domain {
    height: 100%;
    min-height: 100px;
    width: 100%;
    border: 1px dashed #12C3C1;
    display: flex;
    align-items: center;
    justify-content: center;
}

.add-credit-card {
    display: flex !important;
    align-items: center;
    padding: 16px;
    width: 120px !important;
    border: 1px dashed #12C3C1;
    border-radius: 10px;
    height: 253px;
    margin: 46px 0;
    .plus {
        font-size: 46px;
        color: #51D7C1;
    }
    .text {
        color: #353367;
    }
}

.slick-track {
    margin-left: -15px !important;
    margin-right: -15px !important;
    .slick-slide {
        padding-left: 15px;
        padding-right: 15px;
    }
}

.add-credit-card-modal {
    .modal-body {
        padding: 40px 42px;
    }
}

@media only screen and (min-width: 599px) {}

@media only screen and (max-width: 600px) {
    .case-study-col {
        margin-bottom: 24px;
        .case-study-person {
            width: 101px;
            height: 113px;
        }
    }
    #testimonial {
        min-height: calc(100vh - 107.717px);
        padding: 30px 0;
        background-image: url(../images/bg/testimonial-bg.svg);
        background-size: cover;
        background-repeat: no-repeat;
        background-position-x: center;
        background-position-y: bottom;
        .testimonial-carousel {
            margin-bottom: 40px;
        }
    }
}

@media only screen and (max-width: 767px) {
    // Nav Tab Start
    .nav-tabs {
        border-bottom: 2px dashed $grey-e1;
        flex-direction: column;
        .nav-item {
            .nav-link {
                margin-right: 0;
                font-size: $fs-18;
            }
        }
    }
    .tab-content {
        padding: 1.5rem 0;
    }
}

@media only screen and (max-width: 991px) {
    #test {
        background: linear-gradient(to bottom, $white 0%, $white 65%, $green 65.1%, $dark-1b0c4c 100%);
        .content {
            h1 {
                max-width: 75vw;
                margin-bottom: 30px;
            }
            p {
                margin-bottom: 40px;
            }
        }
        .human {
            img {
                max-height: auto;
            }
        }
        .shape-1 {
            right: 0;
            bottom: unset;
            top: 0;
        }
        .shape-2 {
            right: unset;
            left: 0;
            bottom: 30%;
        }
        .shape-3 {
            right: 25vw;
            bottom: 34vh;
        }
        .shape-4 {
            top: 90vh;
            right: 40vw;
        }
    }
    .progress-modal {
        .modal-content {
            .time {
                font-size: $fs-14;
            }
            .human {
                position: relative;
                max-width: 70vw;
                right: 0;
                bottom: -44px;
            }
        }
    }
    .msg-email {
        .bottom {
            .btn {
                width: 100%;
            }
        }
    }
}

@media only screen and (max-width: 1199px) {
    .company-watermark {
        display: none;
    }
    #home {
        background-image: unset;
        background: linear-gradient(to bottom, $white 0%, $white 65%, $green 65.1%, $dark-1b0c4c 100%);
        .content {
            .top {
                padding-bottom: 60px;
                margin-bottom: 50px;
            }
        }
        .shape-1 {
            bottom: 30%;
            left: -20px;
            z-index: 1;
        }
        .shape-2 {
            bottom: -25vw;
            right: 50%;
        }
        .shape-3 {
            left: auto;
            right: calc(40% - 104px);
            bottom: 15%;
        }
        .shape-4 {
            top: 20vw;
            right: 20px;
        }
    }
    #about {
        min-height: calc(100vh - 107.717px);
    }
    #case-study {
        min-height: calc(100vh - 107.717px);
        background-position-x: 80vw;
        .right {
            max-width: unset;
            .main-title {
                margin-bottom: 20px;
            }
            .content {
                max-width: 63vw;
            }
        }
    }
    #test {
        min-height: calc(100vh - 107px);
    }
    #create-account {
        background-image: none;
    }
}

@media only screen and (min-width: 1200px) {}